

<template>
    <!-- Slider
    ============================================= -->
    <section id="slider" class="slider-element min-vh-100 include-header dark" ref="slider">
            <div v-swiper="swiperOption" class="swiper slider-inner">

                <div class="vertical-middle fixed-content">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-lg-12">
                                <h2 class="ls0 fw-bold text-uppercase" style="font-size: 48px;" data-animate="fadeInUp">
                                    Louis Bensa,<br/>
                                    Frédéric Devot,<br/>
                                    Avocats au barreau de Nice
                                </h2>
                                <p class="lead mb-5 fw-bold" data-delay="200" data-animate="fadeInUp">
                                    Louis BENSA et Frédéric DEVOT, Avocats au Barreau de NICE, ont travaillé séparément
                                    puis ensemble, pendant 7 années, au sein de l’association JEAN-CLAUDE BENSA et
                                    Associés.
                                </p>
                                <a href="#presentation" @click="onAnchorLinkClick($event, '#presentation')" class="button button-circle button-light button-large text-end m-0 clearfix" data-animate="fadeInUp" data-delay="400" data-scrollto="#team" data-offset="60">En savoir plus <i class="icon-line-arrow-down"></i></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-wrapper">
                    <div class="swiper-slide min-vh-100 dark promenade">
                        <div class="video-overlay" style="background: rgba(0,0,0,0.3);"></div>
                    </div>
                    <div class="swiper-slide dark min-vh-100 palais-de-justice">
                        <div class="video-overlay" style="background: rgba(0,0,0,0.3);"></div>
                    </div>
                    <div class="swiper-slide dark min-vh-100 gare-du-sud">
                        <div class="video-overlay" style="background: rgba(0,0,0,0.3);"></div>
                    </div>
                </div>
                <div class="slider-arrow-left"><i class="icon-angle-left"></i></div>
				<div class="slider-arrow-right"><i class="icon-angle-right"></i></div>
                <a href="#presentation" @click="onAnchorLinkClick($event, '#presentation')" class="one-page-arrow dark"><i class="icon-angle-down infinite animated fadeInDown"></i></a>
            </div>

            <!--
            

            
            -->


    </section><!-- #slider end -->
    
</template>

<style>

#slider {
    flex: 1 0 auto;
}

#slider .fixed-content {
    text-shadow: 2px 2px 2px black;
}

@media (min-width: 992px) {
    /* 
        We ensure that when header is fixed, we have a minimum of 102px of space before the fixed content
        This trick does not break the vertical alignment centered in the page
    */
    #slider .fixed-content {
        padding-top:204px;
        transform: translate(0px, -102px);
    }
}

#slider .swiper-wrapper {
    position:absolute;
    top:0px;
}

.swiper-slide {
    background-size: cover;
    background-color: rgb(255, 255, 255);   
}

.swiper-slide.palais-de-justice {
    background-image: url('@public/img/bensadevot/nice/palais-de-justice-nice.jpg');
    background-repeat: no-repeat;
    background-position:center bottom;
}

.swiper-slide.promenade {
    background-image: url('@public/img/bensadevot/nice/promenade-nice.jpg'); 
    background-repeat: no-repeat;
    background-position: center right;
}

.swiper-slide.gare-du-sud {
    background-image: url('@public/img/bensadevot/nice/gare-du-sud-nice-2.jpg');
    background-repeat: no-repeat;
    background-position:center bottom; 
}

#slider p {
    font-size: 1.5rem;
    letter-spacing: 1px;
}


</style>

<script lang="ts">
import { animateElements, removeElementsAnimation } from '@root/src/client/theme/canvas-664/canvas.animations';
import { anchorLink } from '@root/src/client/theme/canvas-664/canvas.utils';

// Import Swiper Vue.js components
import { SwiperOptions} from 'swiper';
import { getSwiperOptionsOn } from '@root/src/client/theme/canvas-664/canvas.slider';


import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import type { Ref } from '@fwk-node-modules/vue';


export default defineComponent({
    props: {
        
    },
    components : {
        
    },
    setup(props, context) {
        const headerOffset = 60;

        const slider:Ref<HTMLElement|null> = ref(null);

        const swiperOption:SwiperOptions = {
                //slidesPerView : 3,
                //spaceBetween : 50
            navigation: {
                nextEl: '.slider-arrow-right',
                prevEl: '.slider-arrow-left',
            },
            speed: 500,
            autoplay: {
                delay: 5000
            },
            on: getSwiperOptionsOn({} as HTMLElement)
        }

        onMounted(() => {
            animateElements(slider.value as HTMLElement)
        })

        onBeforeUnmount(() => {
            removeElementsAnimation(slider.value as HTMLElement)
        })

        const onAnchorLinkClick = (evt:Event, path:string) => {
            evt.preventDefault();
            anchorLink(path, headerOffset);
        }

        return {
            slider,
            swiperOption,
            onAnchorLinkClick,
        }

    }
})
</script>